export const ROLE_CANLOG = 'CANLOG';
export const ROLE_ROOT = 'ROOT';
export const ROLE_DEV = 'DEVELOPER';
export const ROLE_CARDS = 'CARDS';
export const ROLE_USER_MGR = 'USER_MGR';
export const ROLE_MODEL_CATALOG = 'MODEL_CATALOG';
export const ROLE_MESSAGES_MGR = 'MESSAGES_MGR';
export const ROLE_CITIES_MGR = 'CITIES_MGR';
export const ROLE_TRANSPORT_PARKS_MGR = 'TRANSPORT_PARKS_MGR';
export const ROLE_VEHICLE_EDIT = 'VEHICLE_EDIT';
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_MANAGER = 'MANAGER';
export const ROLE_OPERATOR = 'OPERATOR';
export const ROLE_OPERATOR_HEAD = 'ROLE_OPERATOR_HEAD';
export const ROLE_CORP_MANAGER = 'CORP_MANAGER';
export const ROLE_OPERATOR_TTRI = 'TTRI_OPERATOR';
export const ROLE_TRANSPORT_PARK_OPERATOR = 'TRANSPORT_PARK_OPERATOR';
export const ROLE_TECHNICIAN = 'TECHNICIAN';
export const ROLE_TECHNICIAN_ADMIN = 'TECHNICIAN_ADMIN';
export const ROLE_REPORTS_DEALER = 'REPORTS_DEALER';
export const ROLE_SECURITY = 'SECURITY';
export const ROLE_FLEET = 'FLEET';
export const ROLE_MONITORING = 'MONITORING';
export const ROLE_VEHICLE_CHANGE = 'VEHICLE_CHANGE';
export const ROLE_USERS_DEBTS_EDIT = 'USERS_DEBTS_EDIT';
export const ROLE_LIMIT_MILEAGE_EDIT = 'LIMIT_MILEAGE_EDIT';
export const ROLE_DEVICE = 'DEVICE';
export const ROLE_FREEDOM_ORG_SESSIONS_CREATE = 'FREEDOM_ORG_SESSIONS_CREATE';
export const ROLE_FAQ = 'FAQ';
export const ROLE_GENESIS_SALE = 'GENESIS_SALE';
export const ROLE_VEHICLES_BOOKED_MGR = 'VEHICLES_BOOKED_MGR';
export const ROLE_CHANGE_PRICE_TARIFF = 'CHANGE_PRICE_TARIFF';
export const ROLE_LOAD_FILES = 'LOAD_FILES';
export const ROLE_DELETE_DEVICE_INFO = 'DELETE_DEVICE_INFO';
export const ROLE_DEVICE_CHECK = 'DEVICE_CHECK';
export const ROLE_MISOS_PRO_CHECKER = 'MISOS_PRO_CHECKER';
export const ROLE_GENESIS_LOUNGE = 'GENESIS_LOUNGE';
export const ROLE_LOGIST = 'LOGIST';
export const ROLE_UKA_MANAGER = 'UKA_MANAGER';
export const ROLE_PARK_VIEW = 'PARK_VIEW';
export const ROLE_SIM_ID_EDIT = 'SIM_ID_EDIT';
export const ROLE_SERVICES_MGR = 'SERVICES_MGR';
export const ROLE_REFUEL_REPORT = 'REFUEL_REPORT';
export const ROLE_HOTLINE_LEADING_SPECIALIST = 'HOTLINE_LEADING_SPECIALIST';
export const ROLE_BUSINESS_SUPPORT = 'BUSINESS_SUPPORT';
export const ROLE_REMOTE_CONTROL = 'REMOTE_CONTROL';
export const ROLE_PAYMENT_REPORT = 'PAYMENT_REPORT';
export const ROLE_PAYMENT_KIA = 'PAYMENT_KIA';
export const ROLE_PAYMENT_HYUNDAI = 'PAYMENT_HYUNDAI';
export const ROLE_CASHIER = 'CASHIER';
export const ROLE_USERS_DEBTS_DELETE = 'USERS_DEBTS_DELETE';
export const ROLE_EDIT_PHONE_MOBIKEY = 'EDIT_PHONE_MOBIKEY';

export const ROLES_CONFIG_MGR = [ROLE_CITIES_MGR, ROLE_MODEL_CATALOG, ROLE_DEV];

export const ROLES_VEHICLES_PROCESSING = [
    ROLE_ROOT,
    ROLE_DEV,
    ROLE_ADMIN,
    ROLE_MANAGER,
    ROLE_TRANSPORT_PARK_OPERATOR,
    ROLE_OPERATOR_TTRI,
    ROLE_TECHNICIAN_ADMIN,
    ROLE_TECHNICIAN,
];
